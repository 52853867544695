<template>
  <WsMain>
    <WsReadSection
      :id="$route.params.id"
      :modelName="modelName"
      :label="label"
      :fields="fields"
      :leftFields="leftFields"
      :rightFields="rightFields"
      v-model="modelData"
    ></WsReadSection>
    <WsCard class="mt-20">
      <div
        class="mb-20"
        v-for="(servicePlanItem, servicePlanItemIndex) in servicePlanItems"
        :key="servicePlanItemIndex"
      >
        <WsInfo
          :label="$_getStateLabel(servicePlanItem)"
          :type="$_getStateTypeFromItemType(servicePlanItem.type)"
          :value="$_getStateValue(servicePlanItem)"
          :items="servicePlanItem.items"
          :unit="servicePlanItem.unit"
        ></WsInfo>
      </div>
    </WsCard>
  </WsMain>
</template>

<script>
import model from "@/__vue2stone_cms/models/service_plan";
import S_Api_ServicePlanItem from "@/__vue2stone_cms/service/api/v1/service_plan_item";
import servicePlanItemModel from "@/__vue2stone_cms/models/service_plan_item";
export default {
  data() {
    return {
      servicePlanItems: [],
      ...model,
      modelData: null,
      leftFields: ["name", "code", "remark"],
      rightFields: [],
    };
  },
  methods: {
    $_getStateValue(item) {
      if (this.modelData && this.modelData.payload) {
        return this.modelData.payload[item.uuid];
      } else {
        return null;
      }
    },
    $_getStateTypeFromItemType(itemType) {
      let _type = "text";
      if (itemType == "custom-items") {
        _type = "select";
      } else if (itemType == "discount") {
        _type = "number";
      } else if (itemType == "annual-times") {
        _type = "number";
      } else if (itemType == "count") {
        _type = "number";
      } else if (itemType == "boolean") {
        _type = "switch";
      }
      return _type;
    },
    $_getStateLabel(item) {
      const _type = servicePlanItemModel.fields.type.items.find((e) => {
        return e.value == item.type;
      });
      return `${item.name} (${_type.text})`;
    },
    async $_fetchServicePlanItems() {
      const res = await S_Api_ServicePlanItem.index();
      this.servicePlanItems = res;
    },
  },
  mounted() {
    this.$_fetchServicePlanItems();
  },
};
</script>