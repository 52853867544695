import axios from 'axios'
export default {
  async index() {
    return new Promise((resolve, reject) => {
      axios
        .get('service_plan_item')
        .then(res => {
          resolve(res.data.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
}